import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import media from "@styles/media"
import styled, { useTheme } from "styled-components"

import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Button from "@components/elements/button"

import { ReactComponent as CircleOverlay } from "@images/comparison/circle-overlay.svg"
import { ReactComponent as KlipsLogo } from "@images/comparison/klips.svg"
import { ReactComponent as PowerMetricsLogo } from "@images/comparison/powermetrics.svg"
import backgroundGradient from "@images/comparison/gradient-hero-blue.jpg"

import {
  ComparisonTable,
  TableRow,
  TableRowHeader,
  TableEntry,
} from "@components/comparison-table"
import Anchor from "@components/elements/anchor"

const GradientBackground = styled.img`
  width: 2500px;
  position: absolute;
  z-index: -3;
  top: 0;
  left: -100px;
  bottom: 0;
  margin: auto;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
`

const BackgroundContainer = styled.div`
  ${media.sm`
		display: none;
	`}

  ${media.md`
		display: none;
	`}

	position: relative;
  height: 0;
  svg {
    z-index: -1;
    position: absolute;
    top: -1000px;
    left: 0;
    right: 0;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
  }
`

const ProductLogo = styled(Flex)`
  width: min-content;

  ${media.sm`
    svg {
      height: 45px;
    }
  `}

  ${media.sm_up`
    svg {
      height: 60px;
    }
  `}
`

const Product = ({ powermetrics }) => {
  const { color, shadow } = useTheme()

  return (
    <Flex
      boxShadow={shadow.subtle}
      alignItems="flex-start"
      gap="2rem"
      gapMd="1.5rem"
      padding="5rem 4rem"
      paddingMd="3rem"
      paddingSm="2rem"
      borderRadius="2rem"
      background="white"
    >
      <ProductLogo>
        {powermetrics ? <PowerMetricsLogo /> : <KlipsLogo />}
      </ProductLogo>
      {powermetrics ? (
        <>
          <Heading as="h2" fontSize="2rem">
            Curate a metric catalog and deliver easy self-serve analytics
          </Heading>
          <Paragraph fontWeight="600" lineHeight="1.7" color={color.indigo600}>
            PowerMetrics is the metrics platform that helps data teams deliver a
            metric catalog and straightforward, metric-centric analytics to
            business users.
          </Paragraph>
          <Button.PowerMetrics noticeColor={color.indigo600}>
            Get started for free
          </Button.PowerMetrics>
        </>
      ) : (
        <>
          <Heading color={color.blue600} as="h2" fontSize="2rem">
            Build pixel-perfect, real-time dashboards and reports
          </Heading>
          <Paragraph fontWeight="600" lineHeight="1.7" color="#013950">
            Klips is a custom dashboard and distribution platform that helps
            data leaders, analysts, and agencies bring real-time data and
            visualizations to their teams or&nbsp;
            <Anchor link="https://www.klipfolio.com/klips/partners"></Anchor>
            clients.
          </Paragraph>
          <Button.Klips href="https://klipfolio.com/klips">
            Start a free 14-day trial
          </Button.Klips>
        </>
      )}
    </Flex>
  )
}

Product.propTypes = {
  powermetrics: PropTypes.bool,
}

const ProductOverview = () => {
  return (
    <Container>
      <Grid columns="1fr 1fr" columnsMd="1fr" gap="3rem" margin="0 0 4rem">
        <Product powermetrics />
        <Product />
      </Grid>
    </Container>
  )
}

const ComparisonPage = () => {
  return (
    <Layout
      background="white"
      title={`Product Comparison: PowerMetrics vs Klips`}
      description={`Find out which Klipfolio product is best for you and your team.`}
      fullWidth
      marginless
    >
      <svg display="none">
        <symbol height="20" width="20" id="checkmark" viewBox="0 0 27 20">
          <path
            d="M8.58044 15.7793L2.17965 9.56003L0 11.6629L8.58044 20L27 2.10291L24.8357 0L8.58044 15.7793Z"
            fill="#404BA8"
          />
        </symbol>
        <symbol height="20" width="20" id="featureless" viewBox="0 0 20 20">
          <path
            d="M10 0C4.48597 0 0 4.48597 0 10C0 15.514 4.48597 20 10 20C15.514 20 20 15.514 20 10C20 4.48603 15.514 0 10 0ZM5.50784 3.83419C6.76923 2.91264 8.32176 2.36693 10 2.36693C14.2089 2.36693 17.6331 5.79109 17.6331 10C17.6331 11.6782 17.0874 13.2308 16.1658 14.4922L5.50784 3.83419ZM3.83419 5.50784L14.4921 16.1659C13.2307 17.0875 11.6782 17.6331 10 17.6331C5.79109 17.6331 2.36693 14.2089 2.36693 10.0001C2.36693 8.32176 2.91264 6.76928 3.83419 5.50789V5.50784Z"
            fill="#404BA8"
          />
        </symbol>
      </svg>
      <Container noPadding fullWidth>
        <Grid
          gap="12rem"
          gapMd="8rem"
          gapSm="6rem"
          margin="0 0 6rem"
          marginMd="0 0 4rem"
          marginSm="0 0 2rem"
        >
          <Flex overflow="hidden">
            <div>
              <Flex center margin="8rem auto 4rem" alignItems="center">
                <Container center text>
                  <header>
                    <Heading fontSize="3rem" lineHeight="120%">
                      Choose the best Klipfolio
                      <br />
                      product for you
                    </Heading>
                  </header>
                </Container>
              </Flex>
              <BackgroundContainer>
                <CircleOverlay />
                <GradientBackground src={backgroundGradient} />
              </BackgroundContainer>
            </div>
            <ProductOverview />
            <Container text>
              <Grid gap="1rem">
                <Paragraph fontSize="1.6rem" fontWeight="700" center>
                  Got questions? Interested in using both products?&nbsp;
                  <Anchor link="/about/contact-us">Contact us</Anchor> any time,
                  we&apos;re happy to help.
                </Paragraph>
              </Grid>
            </Container>
          </Flex>
          <Container>
            <Flex gap="8rem">
              <ComparisonTable title="Data" highlight="Connections">
                <TableRow>
                  <TableRowHeader>130+ service integrations</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Connects to most APIs that use the REST (REpresentational State Transfer) protocol and, if authentication is required, the following methods: Basic HTTP, OAuth, 2-Step, and API Key.`}
                  >
                    REST/URL
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>SQL queries</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Spreadsheets</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Uploaded files</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Cloud file sharing services</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>FTP/SFTP</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Email attachments</TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
              </ComparisonTable>
              <ComparisonTable title="Data" highlight="Preparation">
                <TableRow>
                  <TableRowHeader
                    hover={`Work with raw and modelled data sources in Klips and PowerMetrics. Klips allows raw data to be used in visualizations. PowerMetrics requires modelled data sources.`}
                  >
                    Raw and modelled data sources
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Write formulas using Excel-like functions from the following categories: data manipulation, logic, math, text, date, time, and statistics.`}
                  >
                    Formulas and functions
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>
                    Merge, join, unpivot, and format data
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>
                    dbt Semantic Layer integration
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Metrics and dimensions</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Calculated metrics</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Combine data queries and data prep into one object with a complete set of modelling tools - do the hard work in data feeds so exploration is easy for everyone else.`}
                  >
                    Data feeds
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Visual Query Builder</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>
                    Automatic conversion from JSON/XML to tabular data
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
              </ComparisonTable>
              <ComparisonTable title="Data History and" highlight="Storage">
                <TableRow>
                  <TableRowHeader>Historical data</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>
                    Automatic backfill of historical data
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Stored data updates</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Automatic refresh for cloud data and manual reupload for spreadsheets.`}
                  >
                    Automatic and manual data refresh
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Set a daily time for all of your data sources to refresh.  When your team members log in, they'll have access to the most recent data.`}
                  >
                    Scheduled warm-up refresh
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
              </ComparisonTable>
              <ComparisonTable
                title="Visualizations and"
                highlight="Dashboards"
              >
                <TableRow alignItems="flex-start">
                  <TableRowHeader>Build visualizations</TableRowHeader>
                  <TableEntry
                    text={`Automatically generated and customizable`}
                  />
                  <TableEntry
                    text={`Build using data formulas and functions`}
                  />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Visualization types</TableRowHeader>
                  <TableEntry text={`25+`} />
                  <TableEntry text={`30+`} />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Change the color of values, the background color of table cells, assign display icons, change text style, or replace values with text based on conditional thresholds.`}
                  >
                    Indicators
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Goals and notifications</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow alignItems="flex-start">
                  <TableRowHeader>Dashboard media</TableRowHeader>
                  <TableEntry text={`Add text and images`} />
                  <TableEntry
                    text={`Add text, images, or iframes (to display web content)`}
                  />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Use cascading style sheets (CSS) and JavaScript to  create a unique visual dashboard design that reflects your brand.`}
                  >
                    Custom themes
                  </TableRowHeader>
                  <TableEntry text={`Dashboard-level`} />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Variables are dynamically assigned values (User Input Control, User Property, Company Property, or a MAP, MAPFLAT or SET function), that can be used instead of a specific data selection.`}
                  >
                    Variables
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>White, dark and slate mode</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
              </ComparisonTable>
              <ComparisonTable highlight="Templates">
                <TableRow>
                  <TableRowHeader>Pre-built visualizations</TableRowHeader>
                  <TableEntry text={`400+`} />
                  <TableEntry text={`200+`} />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Dashboard templates</TableRowHeader>
                  <TableEntry text={`20+`} />
                  <TableEntry text={`10+`} />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Automatically generated overview of how a metric is performing in up to 7 visualizations.`}
                  >
                    Metric homepages
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Live dashboard examples</TableRowHeader>
                  <TableEntry />
                  <TableEntry text={`90+`} />
                </TableRow>
              </ComparisonTable>
              <ComparisonTable highlight="Exploration">
                <TableRow>
                  <TableRowHeader>Data Explorer</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>
                    Ad-hoc filtering and segmentation
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Built-in date range controls</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Personalized views</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Save explorations</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>MetricHQ integration</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
              </ComparisonTable>
              <ComparisonTable title="Sharing and" highlight="Distribution">
                <TableRow>
                  <TableRowHeader>User and group sharing</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>View-only role</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`The pre-built user roles included in your account include and exclude permissions according to typical user roles. By adding custom roles to users you can further define which actions they can perform in Klipfolio.`}
                  >
                    Custom roles
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader alignItems="flex-start">
                    Public dashboards
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Includes viewing a dashboard as a slideshow on TVs or wallboards.`}
                  >
                    View dashboard on TVs
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>PDF reports</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Share a PDF or image of dashboards and Klips via scheduled email.`}
                  >
                    Scheduled email reports
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Download the computed data that's shown in the visualization.`}
                  >
                    CSV file download
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Share dashboards and Klips via Slack.`}
                  >
                    Slack integration
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Mobile app</TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Responsive mobile view</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
              </ComparisonTable>
              <ComparisonTable title="Privacy and" highlight="Security">
                <TableRow>
                  <TableRowHeader hover={`Data is backed up every 4 hours.`}>
                    Data backup
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Data is protected using encryption both at rest and in transit.`}
                  >
                    Data encryption
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>GDPR compliance</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>GRC compliance</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Restrict user access to Klipfolio by specifying a single IP address or an IP address range.`}
                  >
                    IP range restrictions
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Detailed event log</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Implement single sign-on to allow users to log in once to access multiple applications rather than logging into the individual applications. Single sign-on can be enabled using SAML 2 or the Custom option.`}
                  >
                    Single sign-on
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>
                    Sign-in with Google, Xero, or Intuit
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>
                    Multi-Factor Authentication (MFA)
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
              </ComparisonTable>

              <ComparisonTable
                title="Client Management &"
                highlight="Customization"
              >
                <TableRow>
                  <TableRowHeader
                    hover={`Create and manage multiple client accounts in one place. Free trial accounts for your clients before they commit.`}
                  >
                    Manage multiple clients
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Bill clients yourself or have it managed by Klipfolio.`}
                  >
                    Billing
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Use scripts to trigger dashboard setup when adding a new client, adding users, sharing content, and creating data sources.`}
                  >
                    Automation
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Includes Branding, CSS, and Domain Alias`}
                  >
                    White-label
                  </TableRowHeader>
                  <TableEntry text={`Can change logo`} />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Allows users to log into the Klipfolio dashboard through a domain of your choosing instead of app.klipfolio.com.`}
                  >
                    Domain aliasing
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Partner Slack community</TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
              </ComparisonTable>
              <ComparisonTable title="Support and" highlight="Resources">
                <TableRow>
                  <TableRowHeader>Email support</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader>In-app chat</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry />
                </TableRow>
                <TableRow>
                  <TableRowHeader>Knowledge Base</TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Use the Klipfolio API to manage Klips including pushing data to the app, managing assets (data sources, dashboards and Klips) and managing users and clients.`}
                  >
                    Public API & guide
                  </TableRowHeader>
                  <TableEntry />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Ensures technical questions are answered at the top of the queue over email.`}
                  >
                    Available priority support
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Monthly one-on-one training with our pro dashboard builders + priority support.`}
                  >
                    Available Data Hero training
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
                <TableRow>
                  <TableRowHeader
                    hover={`Get help building your dashboards and reports.`}
                  >
                    Available professional services
                  </TableRowHeader>
                  <TableEntry enabled />
                  <TableEntry enabled />
                </TableRow>
              </ComparisonTable>
            </Flex>
          </Container>
          <Container text>
            <Grid gap="1rem">
              <Paragraph fontSize="1.6rem" fontWeight="700" center>
                Got questions? Interested in using both products?&nbsp;
                <Anchor link="/about/contact-us">Contact us</Anchor>
                &nbsp;any time, we&apos;re happy to help.
              </Paragraph>
            </Grid>
          </Container>
          <ProductOverview />
        </Grid>
      </Container>
    </Layout>
  )
}

export default ComparisonPage
